<template>
  <div>
    <validation-observer ref="formValidationConsultaAfiliado">
      <b-card>
        <b-row>
          <b-col md="12">
            <h4>Consulta de afiliados</h4>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col md="4">
            <b-form @submit.prevent="onSubmit">
              <b-form-group
                id="frmSerchAfiliado"
                label="N° Afiliado / D.N.I / C.U.I.L. / Apellido"
              >
                <b-input-group>
                  <b-input-group-append>
                    <b-button
                      v-if="hasContent"
                      id="btnClearSearchAfiliado"
                      v-b-tooltip.hover="'Limpiar filtro'"
                      class="closeStyle"
                      size="sm"
                      @click="()=>clearSearchAfiliado()"
                    >
                      <font-awesome-icon icon="fa-solid fa-eraser" />
                    </b-button>
                  </b-input-group-append>
                  <b-form-input
                    id="txtFiltro"
                    v-model="filtro"
                    :disabled="isBusyBuscandoFiltro"
                    autocomplete="off"
                    placeholder=" Búsqueda por ( N° Afiliado - D.N.I. - C.U.I.L. - Apellido )"
                    maxlength="250"
                  />
                </b-input-group>
              </b-form-group></b-form>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmConvenioLaboral"
              label="Convenio laboral"
            >
              <b-form-select
                id="selectConvenioLaboral"
                v-model="afiliadoDto.convenioLaboralId"
                v-b-tooltip.hover="'Seleccione el convenio laboral'"
                :options="optionsConvenioLaboral"
                value-field="id"
                text-field="nombre"
                rounded
                :disabled="isBusyBuscandoConvenioLaboral"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmCategoriaLaboral"
              label="Categoría laboral"
            >
              <b-form-select
                id="selectCategoriaLaboral"
                v-model="afiliadoDto.categoriaLaboralId"
                v-b-tooltip.hover="'Seleccione la categoría laboral'"
                :options="optionsCategoriaLaboral"
                value-field="id"
                text-field="nombre"
                rounded
                :disabled="isBusyBuscandoCategoriaLaboral"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmEstadoLaboral"
              label="Estado laboral"
            >
              <b-form-select
                id="selectEstadoLaboral"
                v-model="afiliadoDto.estadoLaboralId"
                v-b-tooltip.hover="'Seleccione el estado laboral'"
                :options="optionsEstadoLaboral"
                value-field="id"
                text-field="nombre"
                rounded
                :disabled="isBusyBuscandoEstadoLaboral"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <div class="mycontainer">
              <b-button
                id="btnClearSearch"
                v-b-tooltip.hover="'Limpiar filtros'"
                squared
                size="sm"
                class="closeStyle"
                :disabled="isBusyBuscando"
                @click="()=>clearSearch()"
              >

                <font-awesome-icon icon="fa-solid fa-eraser" />
              </b-button>
              <b-button
                id="btnSearchAfiliado"
                v-b-tooltip.hover
                title="Buscar Afiliado"
                variant="primary"
                squared
                size="sm"
                :disabled="isBusyBuscando"
                @click="buscarAfiliado()"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnAddAfiliado"
                v-b-tooltip.hover
                squared
                size="sm"
                title="Agregar nuevo Afiliado"
                variant="success"
                @click="()=>addAfiliado()"
              >
                <feather-icon icon="PlusIcon" />

              </b-button>
              <b-button
                v-if="permisosVer.includes(nameModulo)"
                id="btnExportAfiliado"
                v-b-tooltip.hover
                squared
                size="sm"
                title="Exportar registros a Excel"
                variant="primary"
                @click="() => downloadReporteExcel()"
              >
                <font-awesome-icon
                  icon="fa-solid fa-file-export"
                />

              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              id="frmRazonSocialEmpleador"
              label="Razón Social Empleador"
            >
              <vue-autosuggest
                id="selectRazonSocialEmpleador"
                v-model="selectedRazonSocialEmpleador"
                :suggestions="optionsRazonSocial"
                :limit="7"
                :input-props="{ class: 'form-control', placeholder: 'Seleccione la razón social del empleador' }"
                :disabled="isBusyBuscandoRazonSocial"
                @input="onInputChange"
                @selected="onSuggestionSelected"
              >
                <template slot-scope="{ suggestion }">
                  <div class="suggestion-list">
                    {{ suggestion.item }}
                  </div>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmDelegacion"
              label="Delegación"
            >
              <b-form-select
                id="selectDelegacion"
                v-model="afiliadoDto.delegacionFiltroId"
                v-b-tooltip.hover="'Seleccione la delegación'"
                :options="optionsDelegacion"
                value-field="id"
                text-field="nombre"
                rounded
                :disabled="isBusyBuscandoDelegacion"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmCategoriaSindical"
              label="Categoría sindical"
            >
              <b-form-select
                id="selectCategoriaSindical"
                v-model="afiliadoDto.categoriaSindicalId"
                v-b-tooltip.hover="'Seleccione la categoría sindical'"
                :options="optionsCategoriaSindical"
                value-field="id"
                text-field="nombre"
                rounded
                :disabled="isBusyBuscandoCategoriaSindical"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmEstadoAfiliacion"
              label="Estado afiliación"
            >
              <b-form-select
                id="selectEstadoAfiliacion"
                v-model="afiliadoDto.estadoAfiliacionId"
                v-b-tooltip.hover="'Seleccione el estado de afiliación'"
                :options="optionsEstadoAfiliacion"
                value-field="id"
                text-field="nombre"
                rounded
                :disabled="isBusyBuscandoEstadoAfiliacion"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmMotivoBaja"
              label="Motivo de baja"
            >
              <b-form-select
                id="selectMotivoBaja"
                v-model="afiliadoDto.motivoBajaId"
                v-b-tooltip.hover="'Seleccione el motivo de baja'"
                :options="optionsMotivoBaja"
                value-field="id"
                text-field="nombre"
                rounded
                :disabled="isBusyBuscandoMotivoBaja"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col md="12">
            <b-table
              id="tblAfiliado"
              :items="tableDataAfiliado"
              :busy="isBusyBuscando"
              :fields="fieldsAfiliado"
              outlined
              show-empty
              small
              hover
              sticky-header="70vh"
              :no-border-collapse="true"
              empty-text="No se encontraron resultados para esta búsqueda"
              @row-dblclicked="dobleClik"
            >
              <template v-slot:table-busy>
                <div class="text-center">
                  <b-spinner />
                </div>
              </template>
              <template #cell(estado)="row">
                <small>
                  {{ row.item.estado }}
                </small>
              </template>
              <template #cell(actions)="row">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <b-button
                    v-if="permisosVer.includes(nameModulo) && row.item.cargaCompletada"
                    v-b-tooltip.hover.righttop="'Ver Afiliado'"
                    size="sm"
                    @click="viewAfiliado(row.item, row.index, $event.target)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="permisosUpd.includes(nameModulo) && row.item.cargaCompletada"
                    v-b-tooltip.hover.righttop="'Editar Afiliado'"
                    size="sm"
                    @click="editAfiliado(row.item, row.index, $event.target)"
                  >
                    <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                  </b-button>
                  <b-button
                    v-if="permisosIns.includes(nameModulo) && !row.item.cargaCompletada"
                    v-b-tooltip.hover.righttop="'Completar Carga Afiliado'"
                    size="sm"
                    @click="completeUpload(row.item, row.index, $event.target)"
                  >
                    <font-awesome-icon icon="fa-solid fa-user-pen" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-input-group>

                <b-input-group-append>
                  <b-form-select
                    v-model="pageAfiliado.size"
                    :clearable="false"
                    label="title"
                    size="sm"
                    :options="optionsPaginationSize"
                    :disabled="isBusyBuscando"
                    title="Seleccione la cantidad de resultados por página"
                    @change="buscarAfiliado()"
                  />
                </b-input-group-append>

                <b-pagination
                  id="pgnAfiliado"
                  v-model="currentPage"
                  :total-rows="pageTotalElements"
                  :per-page="pageSize"
                  :disabled="isBusyBuscando"
                  @change="changePaginate"
                />
              </b-input-group>
            </b-col>
          </div>
        </b-row>
      </b-card>
    </validation-observer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { afiliadoResource } from '@/services/afiliadoResource'
import { convenioTrabajoResource } from '@/services/convenioTrabajoResource'
import { categoriaLaboralResource } from '@/services/categoriaLaboralResource'
import { estadoLaboralResource } from '@/services/estadoLaboralResource'
import { empleadoresResource } from '@/services/empleadoresResource'
import { delegacionResource } from '@/services/delegacionResource'
import { categoriaSindicalResource } from '@/services/categoriaSindicalResource'
import { afiliacionEstadoResource } from '@/services/afiliacionEstadoResource'
import { afiliadoReporteResource } from '@/services/afiliadoReporteResource'
import { motivoBajaResource } from '@/services/motivoBajaResource'
import { VueAutosuggest } from 'vue-autosuggest'
import { saveAs } from 'file-saver'

export default {
  components: {
    VueAutosuggest,
  },
  data() {
    return {
      selectedRazonSocialEmpleador: null,
      optionsRazonSocialEmpleador: [],
      optionsRazonSocial: [],
      pageTotalElements: 0,
      currentPage: 1,
      pageSize: 0,
      filtro: null,
      pageAfiliado: {
        page: 0,
        size: 7,
      },
      nameModulo: 'ABM_AFILIADOS',
      isBusyBuscando: false,
      afiliadoDto: {
        nroAfiliado: null,
        dni: null,
        cuil: null,
        apellido: null,
        convenioLaboralId: null,
        categoriaLaboralId: null,
        estadoLaboralId: null,
        razonSocialEmpleadorId: null,
        delegacionFiltroId: null,
        categoriaSindicalId: null,
        estadoAfiliacionId: null,
        motivoBajaId: null,
      },
      isBusyBuscandoCategoriaLaboral: false,
      isBusyBuscandoConvenioLaboral: false,
      isBusyBuscandoCategoriaSindical: false,
      isBusyBuscandoDelegacion: false,
      isBusyBuscandoEstadoAfiliacion: false,
      isBusyBuscandoEstadoLaboral: false,
      isBusyBuscandoMotivoBaja: false,
      isBusyBuscandoRazonSocial: false,
      isBusyBuscandoFiltro: false,
      rolGestorAfiliado: false,
      rolConsultor: false,
      isBusyReporteExcel: false,
      rolConsultorDelegacion: false,
      userData: JSON.parse(localStorage.getItem('ls.CURRENT-USER')),
      optionsConvenioLaboral: [
        { id: null, nombre: 'Todos' },
      ],
      optionsCategoriaLaboral: [
        { id: null, nombre: 'Todas' },
      ],
      optionsEstadoLaboral: [
        { id: null, nombre: 'Todos' },
      ],
      optionsDelegacion: [
        { id: null, nombre: 'Todas' },
      ],
      optionsCategoriaSindical: [
        { id: null, nombre: 'Todas' },
      ],
      optionsEstadoAfiliacion: [
        { id: null, nombre: 'Todos' },
      ],
      optionsMotivoBaja: [
        { id: null, nombre: 'Todos' },
      ],
      tableDataAfiliado: [],
      optionsPaginationSize: [5, 7, 10, 15, 20, 25, 30],
      fieldsAfiliado: [
        {
          key: 'numeroAfiliado', label: 'N° Afiliado',
        },
        {
          key: 'cuil', label: 'C.u.i.l.',
        },
        {
          key: 'apellidoNombreCustom', label: 'Apellido y Nombre',
        },
        {
          key: 'categoriaSindical.nombre', label: 'Categoría Sindical',
        },
        {
          key: 'delegacion.nombre', label: 'Delegación',
        },
        {
          key: 'afiliacionEstado.nombre', label: 'Estado Sindical',
        },
        { key: 'actions', label: 'Acciones', thStyle: { width: '10%' } },
      ],
    }
  },
  computed: {
    hasContent() {
      return (this.filtro != null && this.filtro.length > 0)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'getCurrentUser',
      },
    ),
  },
  watch: {
    'afiliadoDto.convenioLaboralId': {
      handler(newConvenioId, oldConvenioId) {
        if (newConvenioId !== oldConvenioId) {
          if (newConvenioId === null) {
            this.optionsCategoriaLaboral = [{ id: null, nombre: 'Todas' }]
            this.optionsEstadoLaboral = [{ id: null, nombre: 'Todos' }]
            this.getCategoriaLaboralLista()
            this.getEstadoLaboralLista()
          } else {
            this.optionsCategoriaLaboral = [{ id: null, nombre: 'Todas' }]
            this.optionsEstadoLaboral = [{ id: null, nombre: 'Todos' }]
            this.getNewCategoriaLaboralLista(newConvenioId)
            this.getNewEstadoLaboralLista(newConvenioId)
          }
        }
      },
    },
  },
  created() {
    this.buscarAfiliado()
    this.getConvenioLaboralLista()
    this.getCategoriaLaboralLista()
    this.getEstadoLaboralLista()
    this.getDelegacionLista()
    this.getCategoriaSindicalLista()
    this.getEstadoAfiliacionLista()
    this.getMotivoBajaLista()
  },
  methods: {
    onInputChange(text) {
      if (text.length >= 3) {
        const searchQuery = text.trim()
        this.isBusyBuscandoRazonSocial = true
        empleadoresResource().findByRazonSocialEmpleador(searchQuery)
          .then(result => {
            this.optionsRazonSocial = result.map(item => ({ data: [item.razonSocial] }))
            this.optionsRazonSocialEmpleador = result
          })
          .catch(error => {
            console.error('error:', error)
          })
          .finally(() => {
            this.isBusyBuscandoRazonSocial = false
          })
      } else {
        this.optionsRazonSocial = []
      }
    },
    onSuggestionSelected(selectedSuggestion) {
      const selectedId = this.optionsRazonSocialEmpleador.find(item => item.razonSocial === selectedSuggestion.item)?.id
      if (selectedId) {
        this.afiliadoDto.razonSocialEmpleadorId = selectedId
      }
    },
    getLastAfiliadoEmpleador(item) {
      if (item.afiliadoEmpleador && item.afiliadoEmpleador.length > 0) {
        return item.afiliadoEmpleador[item.afiliadoEmpleador.length - 1]
      }
      return {}
    },
    dobleClik(record) {
      if (record.cargaCompletada === null || record.cargaCompletada === false) {
        this.$router.push(`/afiliado/incompleteLoad/${record.id}`)
      } else {
        // La carga está completada, abrimos el detalle. y NO el wizard
        this.$router.push(`/afiliado/view/${record.id}`)
      }
    },
    onSubmit() {
      if (!this.isBusyBuscando) {
        this.buscarAfiliado()
      }
    },
    clearSearchAfiliado() {
      this.filtro = ''
    },
    clearSearch() {
      this.filtro = ''
      this.afiliadoDto.convenioLaboralId = null
      this.afiliadoDto.categoriaLaboralId = null
      this.afiliadoDto.estadoLaboralId = null
      this.selectedRazonSocialEmpleador = null
      this.afiliadoDto.razonSocialEmpleadorId = null
      this.afiliadoDto.delegacionFiltroId = null
      this.afiliadoDto.categoriaSindicalId = null
      this.afiliadoDto.estadoAfiliacionId = null
      this.afiliadoDto.motivoBajaId = null
    },
    viewAfiliado(item) {
      this.$router.push(`/afiliado/view/${item.id}`)
    },
    editAfiliado(item) {
      this.$router.push(`/afiliado/edit/${item.id}`)
    },
    completeUpload(item) {
      this.$router.push(`/afiliado/incompleteLoad/${item.id}`)
    },
    addAfiliado() {
      this.$router.push('/afiliado/new')
    },
    buscarAfiliado() {
      this.currentPage = 1
      this.pageAfiliado.page = 0
      this.getAfiliadoByFilter()
    },
    getAfiliadoByFilter() {
      this.tableDataAfiliado = []
      this.isBusyBuscando = true
      this.getBuscarRoles()
      const filtros = {
        filtro: this.filtro,
        convenioLaboralId: this.afiliadoDto.convenioLaboralId,
        categoriaLaboralId: this.afiliadoDto.categoriaLaboralId,
        estadoLaboralId: this.afiliadoDto.estadoLaboralId,
        razonSocialEmpleadorId: this.afiliadoDto.razonSocialEmpleadorId,
        delegacionFiltroId: this.afiliadoDto.delegacionFiltroId,
        categoriaSindicalId: this.afiliadoDto.categoriaSindicalId,
        estadoAfiliacionId: this.afiliadoDto.estadoAfiliacionId,
        motivoBajaId: this.afiliadoDto.motivoBajaId,
        rolConsultorDelegacion: this.rolConsultorDelegacion,
        rolConsultor: this.rolConsultor,
        rolGestor: this.rolGestorAfiliado,
        delegacionId: this.userData.delegacion.id,
        pageNumber: this.pageAfiliado.page,
        pageSize: this.pageAfiliado.size,
        sort: 'apellidoNombreCustom',
        order: 'asc',
      }
      this.consultaBeneSolicitudFiltroDto = filtros
      afiliadoResource().postAfiliadoByFilter(filtros)
        .then(resultGetByAfiliadoByFilter => {
          this.tableDataAfiliado = resultGetByAfiliadoByFilter.content
          this.pageTotalElements = resultGetByAfiliadoByFilter.totalElements
          this.pageNumber = resultGetByAfiliadoByFilter.number
          this.pageSize = resultGetByAfiliadoByFilter.size
        }).catch(error => {
          console.error('errors:', error)
          this.tableDataAfiliado = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
    downloadReporteExcel() {
      this.isBusyReporteExcel = true
      return afiliadoReporteResource()
        .getReporteExcelConsultaAfiliados(this.consultaBeneSolicitudFiltroDto)
        .then(resultExcelReport => {
          const date = new Date()
          const fileDate = this.formatDateTime(date, 'DD_MM_YYYY_HH_mm_ssZZ', 'DD-MM-YYYY-HH_mm_ssZZ')
          // const blob = new Blob([resultExcelReport], { type: 'application/octet-stream;charset=utf-8' })
          const blob = new Blob([resultExcelReport], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          saveAs(blob, `Consulta_afiliados_${fileDate}.xlsx`)
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isBusyReporteExcel = false
        })
    },
    getBuscarRoles() {
      this.userData.roles.forEach(rol => {
        if (rol.codigo === 'GESTOR_AFILIADOS') {
          this.rolGestorAfiliado = true
        } else if (rol.codigo === 'CONSULTOR_AFILIADOS') {
          this.rolConsultor = true
        } else if (rol.codigo === 'CONSULTOR_AFILIADOS_DELEGACION') {
          this.rolConsultorDelegacion = true
        }
      })
    },
    getConvenioLaboralLista() {
      this.isBusyBuscandoConvenioLaboral = true
      convenioTrabajoResource().findAllByIsActivoConvenioLaboral().then(result => {
        this.optionsConvenioLaboral = this.optionsConvenioLaboral.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoConvenioLaboral = false
        })
    },
    getCategoriaLaboralLista() {
      this.isBusyBuscandoCategoriaLaboral = true
      categoriaLaboralResource().findAllCategoriaLaboral().then(result => {
        this.optionsCategoriaLaboral = this.optionsCategoriaLaboral.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoCategoriaLaboral = false
        })
    },
    getNewCategoriaLaboralLista(convenioId) {
      this.isBusyBuscandoCategoriaLaboral = true
      categoriaLaboralResource().findCategoriaLaboralByConvenio(convenioId).then(result => {
        this.optionsCategoriaLaboral = this.optionsCategoriaLaboral.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoCategoriaLaboral = false
        })
    },
    getEstadoLaboralLista() {
      this.isBusyBuscandoEstadoLaboral = true
      estadoLaboralResource().findAllEstadoLaboral().then(result => {
        this.optionsEstadoLaboral = this.optionsEstadoLaboral.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstadoLaboral = false
        })
    },
    getNewEstadoLaboralLista(convenioId) {
      this.isBusyBuscandoEstadoLaboral = true
      estadoLaboralResource().findEstadoLaboralByConvenio(convenioId).then(result => {
        this.optionsEstadoLaboral = this.optionsEstadoLaboral.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstadoLaboral = false
        })
    },
    getDelegacionLista() {
      this.isBusyBuscandoDelegacion = true
      delegacionResource().findAllByIsActivoDelegacion().then(result => {
        this.optionsDelegacion = this.optionsDelegacion.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoDelegacion = false
        })
    },
    getCategoriaSindicalLista() {
      this.isBusyBuscandoCategoriaSindical = true
      categoriaSindicalResource().findAllByIsActivoCategoriaSindical().then(result => {
        this.optionsCategoriaSindical = this.optionsCategoriaSindical.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoCategoriaSindical = false
        })
    },
    getEstadoAfiliacionLista() {
      this.isBusyBuscandoEstadoAfiliacion = true
      afiliacionEstadoResource().findAllByIsActivoAfiliacionEstado().then(result => {
        this.optionsEstadoAfiliacion = this.optionsEstadoAfiliacion.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstadoAfiliacion = false
        })
    },
    getMotivoBajaLista() {
      this.isBusyBuscandoMotivoBaja = true
      motivoBajaResource().findAllByIsActivoMotivoBaja().then(result => {
        this.optionsMotivoBaja = this.optionsMotivoBaja.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoMotivoBaja = false
        })
    },
    changePaginate(item) {
      this.pageAfiliado.page = item - 1
      this.isBusyBuscando = true
      this.getAfiliadoByFilter()
    },
  },
}
</script>
<style>
.closeStyle {
  border-color: #cad1d7;
}
.text-red {
  color: red;
}
.mycontainer {
  text-align: center;
  padding-top: 28px;
}
.suggestion-list {
  cursor: pointer;
}
</style>

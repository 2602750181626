import axios from 'axios'
import {
  getExcelConsultaAfiliado,
} from '@/utils/paths'

const pathGetExcelConsultaAfiliado = getExcelConsultaAfiliado

export function afiliadoReporteResource() {
  function getReporteExcelConsultaAfiliados(consultaAfiliadoFiltroDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${pathGetExcelConsultaAfiliado}exportExcel/`, consultaAfiliadoFiltroDto, { responseType: 'blob' })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    getReporteExcelConsultaAfiliados,
  }
}
